import useHttp from '@/comp-functions/useHttp'
/**
 * Logic for create new tags
 * Will use in many component, be careful when want to change this function
 * @param {Array} tagFormArray array of tag form that you want to post to api, pass it so direclty change the id to new pushed tags id
 */
export default function usePushTags () {
  const { $post } = useHttp()
  
  const pushTagsToAPI = async newTag => {
    const { data } = await $post({
      url: 'master/tag',
      data: {
        name: newTag.name
      }
    })

    return data.id
  }

  return {
    pushTagsToAPI
  }
}